import { Project } from '@agentsonly/models';
import { yupResolver } from '@hookform/resolvers/yup';
import { App, Button, Col, Row, Switch, Typography } from 'antd';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { projectClientsAtom } from '../../../atoms/client';
import { projectByIdAtom, updateProjectAtom } from '../../../atoms/project';
import { FormInput } from '../../../shared/ui/form/FormInput';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { useCloudTask } from '../../hooks/useCloudTask';
import { Heading } from '../styledComponents';
import { AGENT_TYPES, DEFAULT_VALUE } from './consts';
import { projectDetailsValidator } from './schema';
import { Container, StyledSpace } from './styledComponents';

const { Text, Paragraph } = Typography;

interface Props {
  project: Project;
  disabled: boolean;
}

type FieldValues = Pick<Project, 'billingName' | 'billingAddress'>;

export const ProjectDetails: React.FC<Props> = ({ project, disabled }) => {
  const { notification } = App.useApp();
  const [currentProject] = useAtom(projectByIdAtom);
  const [, updateProject] = useAtom(updateProjectAtom);
  const [{ data: projectClientMapping }, fetchProjectClientId] =
    useAtom(projectClientsAtom);
  const projectClient = projectClientMapping?.[project.id];

  const billingName = currentProject[project.id]?.billingName || '';
  const billingAddress = currentProject[project.id]?.billingAddress || '';
  const agentType = project.employeeProject
    ? AGENT_TYPES.EMPLOYEES
    : AGENT_TYPES.GIG_AGENTS;

  const { updateCloudTask, isLoading, isEnabledCloudTasks } = useCloudTask(
    project.id,
  );

  const onToggleCloudTask = async (checked: boolean) => {
    try {
      await updateCloudTask(checked);
      notification.success({
        message: 'Cloud Task Configuration updated successfully',
      });
    } catch (err) {
      notification.error({
        message: 'Something went wrong, Please try again later',
      });
    }
  };

  const form = useForm<FieldValues>({
    mode: 'onChange',
    resolver: yupResolver(projectDetailsValidator),
    defaultValues: {
      billingName,
      billingAddress,
    },
  });

  const handleSubmit = async (values: FieldValues) => {
    try {
      await updateProject({ id: project.id, ...values });
      form.reset({ ...values });
      notification.success({ message: 'Project details updated successfully' });
    } catch (err) {
      notification.error({
        message: 'Something went wrong when updating project details',
      });
    }
  };

  useEffect(() => {
    form.reset({ billingName, billingAddress });
    if (!projectClient) {
      fetchProjectClientId(project.id);
    }
  }, [project.id, projectClientMapping]);

  // If we set form.formState.isDirty directly
  // then we caught an issue that the form is unable to save if just typed one character
  // when first load
  const isFormDirty = form.formState.isDirty;
  const buttonDisabled = !form.formState.isValid || !isFormDirty || disabled;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Row>
          <Col span={24}>
            <Heading level={5}>Details</Heading>
          </Col>
          <Container span={22}>
            <Row gutter={80}>
              <Col span={8}>
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Billing Name</Text>
                    <FormInput
                      name="billingName"
                      type="string"
                      placeholder="Enter Name"
                      height={48}
                    />
                  </StyledSpace>
                </Row>
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Billing Address</Text>
                    <FormInput
                      name="billingAddress"
                      type="textarea"
                      placeholder={`Street number and name\nCity, State\nPostal code, Country`}
                      height={100}
                    />
                  </StyledSpace>
                </Row>
                <Row>
                  <Button
                    loading={form.formState.isSubmitting}
                    htmlType="submit"
                    type="primary"
                    ghost
                    disabled={buttonDisabled}
                  >
                    Save Billing Information
                  </Button>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Project Name</Text>
                    <Text strong>{project.name}</Text>
                  </StyledSpace>
                </Row>
                <Spacer size={24} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Description</Text>
                    <Paragraph
                      strong
                      ellipsis={{ tooltip: project.description, rows: 4 }}
                    >
                      {project.description}
                    </Paragraph>
                  </StyledSpace>
                </Row>
                <Spacer size={10} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Agent Type</Text>
                    <Text>{agentType}</Text>
                  </StyledSpace>
                </Row>
                <Spacer size={24} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Cloud Task Configuration</Text>
                    <Switch
                      disabled={disabled}
                      checked={isEnabledCloudTasks}
                      loading={isLoading}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      onClick={onToggleCloudTask}
                    />
                  </StyledSpace>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Project ID</Text>
                    <Text>{project.id}</Text>
                  </StyledSpace>
                </Row>
                <Spacer size={24} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Client ID</Text>
                    <Text strong>{projectClient?.name ?? DEFAULT_VALUE}</Text>
                  </StyledSpace>
                </Row>
                <Spacer size={24} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Active</Text>
                    <Paragraph>{project.active.toString()}</Paragraph>
                  </StyledSpace>
                </Row>
                <Spacer size={10} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={19}>
                    <Text type="secondary">Created at</Text>
                    <Paragraph>
                      {project.createdAt
                        ? format(project.createdAt, 'PPpp')
                        : ''}
                    </Paragraph>
                  </StyledSpace>
                </Row>
              </Col>
            </Row>
          </Container>
        </Row>
      </form>
    </FormProvider>
  );
};
