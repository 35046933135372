import { Agent } from '@agentsonly/models';
import { useAuth0 } from '@auth0/auth0-react';
import { App, Col, Input, Modal, Row, Spin } from 'antd';
import { SwitchChangeEventHandler } from 'antd/lib/switch';
import { format } from 'date-fns';
import { useAtom } from 'jotai';
import { FC, useEffect, useState } from 'react';

import {
  updateAgentAtom,
  updateAgentStateAtom,
} from '../../../../../atoms/agent';
import { Auth0API } from '../../../../../shared/api/auth0';
import { Button } from '../../../../../shared/ui/Button';
import { Switch } from '../../../../../shared/ui/form/FormSwitch/Switch';
import { Spacer } from '../../../../../shared/ui/Spacer/styledComponents';
import { changeAgentEmail } from '../../../../../utils/adminFunctions';
import { AGENT_TYPES, NOT_APPLICABLE_VALUE } from '../../../../../utils/consts';
import { useFetchSignedDocuments } from '../../../../hooks/useFetchSignedDocuments';
import { AccessDropDown } from '../../../AccessDropDown';
import { BankAndLegalDocumentSection } from '../../../BankAndLegalDocumentSection';
import { EmailUpdateModal, EmailUpdateValues } from '../../../EmailUpdateModal';
import { BorderedRow } from '../../styledComponents';
import { Label, Value } from './styledComponents';

type Props = {
  agent: Agent;
  disabled: boolean;
};
export const GeneralInformation: FC<Props> = ({ agent, disabled }) => {
  const { message } = App.useApp();
  const { getAccessTokenSilently } = useAuth0();
  const [, updateAgent] = useAtom(updateAgentAtom);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [newPasswordValue, setNewPasswordValue] = useState('');
  const [emailUpdateModalVisible, setEmailUpdateModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [, updateAgentState] = useAtom(updateAgentStateAtom);
  const { legalDocuments, fetchingDocument } = useFetchSignedDocuments({
    agentId: agent.id,
  });

  useEffect(() => {
    const fetchAuth0PhoneNumber = async () => {
      const { userMetadata } = await Auth0API.getAuth0User(agent.email);
      const userPhoneNumber = userMetadata?.phoneNumber ?? agent.phoneNumber;
      setPhoneNumber(userPhoneNumber);
    };

    fetchAuth0PhoneNumber();
  }, [agent, setPhoneNumber]);

  const passwordOkClicked = async () => {
    setPasswordModalVisible(false);
    message.loading('Updating Password...');

    try {
      const token = await getAccessTokenSilently();
      await Auth0API.updateAuth0UserPassword(agent.id, newPasswordValue, token);
      message.success('Password updated');
    } catch (err) {
      if (err instanceof Error) {
        message.error(`Couldn't update password: ${err.message}`);
      }
    }
  };

  const passwordCancelClicked = () => {
    setPasswordModalVisible(false);
  };

  const handleDemoChange: SwitchChangeEventHandler = async (checked) => {
    try {
      await updateAgent({ id: agent.id, demo: checked });
      message.success('Agent has been updated');
    } catch (e) {
      if (e instanceof Error) {
        message.error(`Couldn't update the Agent: ${e.message}`);
      }
    }
  };

  const displayEmailUpdateModal = () => {
    setEmailUpdateModalVisible(true);
  };

  // Password Modal
  const displayPasswordModal = () => {
    setPasswordModalVisible(true);
    setNewPasswordValue('');
  };

  const onEmailUpdateCancelClick = () => {
    setEmailUpdateModalVisible(false);
  };

  const emailChangeOkClick = async (values: EmailUpdateValues) => {
    const { email } = values;
    try {
      await changeAgentEmail({ email, id: agent.id });
      setEmailUpdateModalVisible(false);
      updateAgentState({ id: agent.id, email });
      message.success('Email has been updated');
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  };

  if (fetchingDocument) {
    return (
      <Row align="middle" justify="center">
        <Spin />
      </Row>
    );
  }

  return (
    <>
      <BorderedRow>
        <Modal
          title="Enter the new password"
          open={passwordModalVisible}
          onOk={passwordOkClicked}
          onCancel={passwordCancelClicked}
        >
          <Input
            placeholder=""
            value={newPasswordValue}
            onChange={(newValue) => setNewPasswordValue(newValue.target.value)}
          />
        </Modal>
        <EmailUpdateModal
          open={emailUpdateModalVisible}
          onCancel={onEmailUpdateCancelClick}
          onOk={emailChangeOkClick}
        />
        <Col span={24}>
          <Row>
            <Col span={8}>
              <Label>Agent Name</Label>
              <Value>
                {agent?.firstName}&nbsp;
                {agent?.lastName}
              </Value>
            </Col>
            <Col span={8}>
              <Label>Email Address</Label>
              <Value>
                {agent?.email ?? 'Email Not Found'}
                <Col span={24}>
                  <Row>
                    <Col>
                      <Spacer size={14} axis="vertical" />
                      <Button
                        ghost
                        type="primary"
                        onClick={displayEmailUpdateModal}
                      >
                        Update Email
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Value>
            </Col>
            <Col span={8}>
              <Label>Agent Id</Label>
              <Value>{agent?.id ?? NOT_APPLICABLE_VALUE}</Value>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Label>Country</Label>
              <Value>
                {agent?.address?.countryCode ?? NOT_APPLICABLE_VALUE}
              </Value>
            </Col>
            <Col span={8}>
              <Label>Date Created</Label>
              <Value>{format(agent?.createdAt ?? new Date(), 'P')}</Value>
            </Col>
            <Col span={8}>
              <Label>Phone number</Label>
              <Value>{phoneNumber ?? NOT_APPLICABLE_VALUE}</Value>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Label>Street</Label>
              <Value>{agent?.address?.street ?? NOT_APPLICABLE_VALUE}</Value>
            </Col>
            <Col span={8}>
              <Label>City</Label>
              <Value>{agent?.address?.city ?? NOT_APPLICABLE_VALUE}</Value>
            </Col>
            <Col span={8}>
              <Label>Agent Type</Label>
              <Value>
                {agent?.employeeClientId
                  ? AGENT_TYPES.EMPLOYEES
                  : AGENT_TYPES.GIG_AGENTS}
              </Value>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Label>Province</Label>
              <Value>{agent?.address?.state ?? NOT_APPLICABLE_VALUE}</Value>
            </Col>
            <Col span={8}>
              <Label>Postal Code</Label>
              <Value>{agent?.address?.zip ?? NOT_APPLICABLE_VALUE}</Value>
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                ghost
                onClick={() => {
                  displayPasswordModal();
                }}
                disabled={disabled}
              >
                Update Account Password
              </Button>
              <Col span={24} style={{ marginTop: 8 }}>
                <AccessDropDown agentId={agent.id} disabled={disabled} />
              </Col>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Switch
                label="Demo"
                checked={agent.demo}
                onChange={handleDemoChange}
                disabled={disabled}
              />
            </Col>
          </Row>
        </Col>
      </BorderedRow>
      <BorderedRow $borderRadius={8}>
        <BankAndLegalDocumentSection
          disabled={disabled}
          agent={agent}
          legalDocuments={legalDocuments}
        />
      </BorderedRow>
    </>
  );
};
